.editTextCustomWidth {
    display: flex;
    justify-content: flex-end; 
    align-items: center;
    width: 150px; 
}
.editTextContainer .editInput {
    flex-grow: 1; /* Allows the input to grow and fill available space */
    margin-right: 10px; /* Spacing between input and buttons */
}

.editTextContainer .editButton,
.editTextContainer .saveButton,
.editTextContainer .cancelButton {
    flex-shrink: 0; /* Prevents buttons from shrinking */
}